<template>
  <div class="privacyPolicy flex-column">
    <div class="inner_wrapper">
      <h1>Политика конфиденциальности</h1>
      <p>
        Государственное предприятие «Туркменулаггозегчилик» Агентства
        «Туркменавтоулаглары» Агентства транспорта и коммуникаций при Кабинете
        Министров Туркменистана обязуется обеспечить конфиденциальность
        информации о своих клиентах.
      </p>

      <p>
        Мы заботимся о защите личной информации, передаваемой Вами предприятии
        посредством нашего приложения, соблюдая правила по сохранению ее
        безопасности и использования только в законных целях.
      </p>

      <p>
        Мы никогда умышленно не передадим Вашу личную информацию третьим лицам,
        кроме как для использования ее для обработки Вашего запроса, без
        предварительного получения Вашего разрешения или если не будем иметь
        правовое обязательство.
      </p>

      <p>
        Вы всегда можете проверить информацию, переданную Вами нам, связавшись с
        нами.
      </p>

      <h1>Защита информации</h1>

      <p>
        Мы принимаем необходимые меры для защиты данных от несанкционированного
        доступа, изменения, раскрытия или уничтожения. Это включает в себя
        внутренние проверки процессов сбора, хранения и обработки данных и меры
        безопасности, а также меры по обеспечению физической безопасности данных
        для предотвращения незаконного доступа к системам, в которых мы храним
        личные данные.
      </p>

      <p>
        Мы предоставляем доступ к личным данным клиентов только тем работникам,
        поставщикам и агентам государственном предприятии, которым эта
        информация необходима для работы, развития и совершенствования наших
        услуг. Данные лица должны поддерживать конфиденциальность указанной
        информации на договорной основе.
      </p>

      <h1>Политика безопасности</h1>

      <p>
        Для обеспечения безопасности Вашей личной информации при оплачивании
        административного нарушении на сайте
        <a href="https://tugdk.gov.tm/">www.tugdk.gov.tm</a>, используется
        протокол безопасных соединений SSL (Secure Sockets Layer) с технологией
        шифрования 128 бит для безопасной передачи Ваших данных. Это подключение
        шифрует всю Вашу личную информацию, включая номер кредитной/дебитной
        карты, чтобы ее невозможно было прочесть во время передачи по Интернету.
      </p>

      <p>
        В случае, если Ваш интернет-браузер не поддерживает SSL, мы рекомендуем
        Вам обновить текущую версию браузера на самую последнюю, для увеличения
        уровня безопасности проводимых транзакций, в противном случае, передача
        Вашей личной информации может быть недостаточно защищена, и в данном
        случае Предприятие снимает с себя всю ответственность.
      </p>

      <h1>Электронная рассылка</h1>

      <p>
        Подписавшись на такие услуги Предприятие, как электронная рассылка, Вы
        тем самым разрешаете Предприятию использовать ваш телефонный номер для
        отправки вам информационных бюллетеней и других сообщений, таких как
        опросы и специальные предложения.
      </p>

      <h1>Политика в отношении использования Cookie-файлов</h1>

      <p>
        Cookie (куки) – это небольшой кусок данных, передаваемый с сайта и
        временно хранимый в браузере пользователя. Браузер затем возвращает
        cookie веб-серверу при следующей попытке открыть страницу
        соответствующего сайта. Cookie-файлы позволяют веб-сайту
        персонализировать Ваш визит и улучшить пользовательский опыт при
        повторном посещении сайта.
      </p>

      <p>
        Посещая <a href="https://tugdk.gov.tm/">www.tugdk.gov.tm</a>, Вы
        соглашаетесь с тем, что предприятие «Туркменулаггозегчилик» размещает
        cookie-файлы на вашем устройстве.
      </p>

      <p>
        Политика обработки личных данных пользователей для мобильного приложения
        "Adminstratiw jerime"
      </p>

      <h1>Введение</h1>

      <p>
        Эта Политика обработки личных данных описывает, как компания
        «Туркменулаггозегчилик» собирает, использует и защищает личные данные
        пользователей своего мобильного приложения. Мы стремимся обеспечить
        конфиденциальность и безопасность ваших личных данных в соответствии с
        законами и нормами о защите данных.
      </p>

      <h1>Собираемые данные</h1>

      <p>
        При использовании мобильного приложения "Adminstratiw jerime" мы можем
        собирать следующие категории личных данных:
      </p>

      <p>
        - Информация о пользователе: имя, фамилия, дата рождения, контактная
        информация (такие как адрес электронной почты и номер телефона).
      </p>

      <p>
        - Данные для оплачивания нарушении: данные о протоколе, местах
        назначения, даты и прочие данные, связанные с оплачиванием нарушении.
      </p>

      <p>
        - Информация о платежах: данные о платежных картах, банковских
        реквизитах и другие данные, необходимые для обработки платежей.
      </p>

      <p>
        - Информация об использовании приложения: данные о ваших взаимодействиях
        с приложением, предпочтения и настройки.
      </p>

      <h1>Цели сбора данных</h1>

      <p>Мы собираем личные данные пользователей с целью:</p>

      <p>
        - Обеспечение доступа к функциональности мобильного приложения
        "Adminstratiw jerime".
      </p>

      <p>- Обработки протокола и оплачивании нарушении.</p>

      <p>- Обеспечение качественного обслуживания и поддержки пользователей.</p>

      <p>- Улучшение функциональности и удобства использования приложения.</p>

      <p>- Соблюдение законодательства и норм о защите данных.</p>

      <h1>Согласие на обработку данных</h1>

      <p>
        Используя мобильное приложение "Adminstratiw jerime", вы предоставляете
        своё явное согласие на сбор, обработку и хранение ваших личных данных в
        соответствии с настоящей Политикой.
      </p>

      <h1>Защита данных</h1>

      <p>
        Мы предпринимаем необходимые технические и организационные меры для
        обеспечения безопасности ваших личных данных. Все собранные данные
        хранятся на защищенных серверах и обрабатываются с соблюдением
        современных стандартов безопасности.
      </p>

      <h1>Передача данных третьим лицам</h1>

      <p>
        Мы можем передавать ваши личные данные третьим лицам, таким как партнеры
        по обработке платежей или службы поддержки. Все передачи данных
        осуществляются в соответствии с применяемым законодательством и согласно
        настоящей Политике.
      </p>

      <h1>Права пользователей</h1>

      <p>
        Вы имеете право запросить доступ к своим личным данным, внести поправки,
        исправления или удаление некорректных данных, а также ограничить
        обработку ваших данных. Вы также можете в любой момент отозвать свое
        согласие на обработку данных.
      </p>

      <h1>Изменения в политике</h1>

      <p>
        Мы оставляем за собой право вносить изменения в настоящую Политику
        обработки личных данных. Любые изменения будут опубликованы на нашем
        веб-сайте или мобильном приложении.
      </p>

      <h1>Контакты</h1>

      <p>
        Вопросы или претензии по поводу защиты ваших личных данных со стороны
        Государственное предприятие «Туркменулаггозегчилик» Агентства
        «Туркменавтоулаглары» Агентства транспорта и коммуникаций при Кабинете
        Министров Туркменистана Вы можете отправлять по адресу
        <a href="mailto:info@tugdk.gov.tm">info@tugdk.gov.tm</a>. Мы ответим Вам
        как можно быстрее.
      </p>

      <p>
        Для просмотра и изменения своих личных данных, а также для предъявления
        претензий по поводу использования вашей личной информации предприятием
        вы можете выслать соответствующий письменный запрос и копию документа,
        удостоверяющего вашу личность, по адресу:
      </p>

      <p>
        Государственное предприятие «Туркменулаггозегчилик» Агентства
        «Туркменавтоулаглары» Агентства транспорта и коммуникаций при Кабинете
        Министров Туркменистана
      </p>

      <p>Ашгабат 744008</p>

      <p>Туркменистан</p>

      <p>Телефон: +99 312 390251 / 390270</p>

      <p>Э-почта: <a href="mailto:info@tugdk.gov.tm">info@tugdk.gov.tm</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
